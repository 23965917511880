export const shema = {
  completed: false,
  finalist: false,
  score: 0,
  seconds: 0,
  milliseconds: 0,

  gameOneCompleted: false,
  gameTwoCompleted: false,
  gameThreeCompleted: false,

  gameOneStartedAt: false,
  gameTwoStartedAt: false,
  gameThreeStartedAt: false,

  gameOneFinishedAt: false,
  gameTwoFinishedAt: false,
  gameThreeFinishedAt: false,

  gameOneScore: 0,
  gameTwoScore: 0,
  gameThreeScore: 0,

  gameOneAnswers: [],
  gameOneQuestions: [],
  gameOneQuestionsAnswered: 0,
  gameOneQuestionsTotal: 0,
  gameOneCurrentQuestion: false,


  gameTwoGoalsFound: 0,

  gameThreeAnswers: [],
  gameThreeMatch: false,
  
};
