function TermsText() {
  return (
    <>
      <div className="terms text-sm flex flex-col gap-8">
        <h1 className="text-center text-xl">
          <strong>
            BASES DE LA DINÁMICA DENOMINADA “EL FAN MÁS REAL DEL REAL”
          </strong>
        </h1>
        <p>
          adidas de México, S.A. de C.V., con domicilio en Boulevard Adolfo Ruíz
          Cortines N° 3642. Piso 11, Alcaldía Álvaro Obregón, Colonia Jardines
          del Pedregal, Ciudad de México, (en adelante el “Organizador”)
          desarrolla la presente dinámica denominada “EL FAN MÁS REAL DEL REAL”
          (en adelante la “Dinámica”).
        </p>
        <h2 className="text-lg text-center">
          <strong>Términos y Condiciones</strong>
        </h2>
        <p>
          <strong>DINÁMICA/OBJETO:</strong> El Organizador realiza la Dinámica
          que consiste en que miembros activos afiliados al Programa adidas
          “AdiClub”, (en adelante “el Programa”) y los Participantes que acudan
          a las Sucursales Participante señaladas en estas Bases podrán
          participar en la Dinámica, en la que deberán registrarse dentro de la
          aplicación electrónica para dispositivos móviles denominada “adidas
          app” (en adelante “la App”), en la página web www.adidas.mx (el “Sitio
          Web”), o bien con un código de registro otorgado a través de las
          tiendas oficiales del Real Madrid en Ciudad de México enlistadas en
          estas Bases y poder ser acreedores a 1 (un) viaje todo incluido a
          Madrid España. El Organizador informa que habrá sólo 1 (un) ganador en
          total. Las Bases de participación a dicha Dinámica (en adelante las
          “Bases”) estarán exhibidas en la app y en el Sitio Web. BASES
        </p>
        <p>
          <strong>1) PARTICIPANTES:</strong> Podrán participar en la dinámica
          todas aquellas personas físicas que sean miembros activos del Programa
          “AdiClub” adidas y los primeros 1,000 (mil) Participantes que acudan a
          las Sucursales Participantes para obtener su respectivo cupón y poder
          registrarse en la Dinámica (500 cupones por cada Sucursal
          Participante), con domicilio en los Estados Unidos Mexicanos y que
          cumplan con todos y cada uno de los requisitos establecidos en los
          términos y condiciones de estas Bases (en adelante los
          “Participantes”). Los Participantes deberán ser mayores de edad, según
          lo establece la Ley respectiva y deberán otorgar pleno consentimiento
          para el uso y explotación de los derechos de su imagen y nombre para
          fines promocionales y publicitarios relacionados con esta Dinámica. La
          participación en esta Dinámica implica el conocimiento y aceptación
          total de la mecánica, términos y condiciones de esta. Cada
          Participante acepta obligarse por las reglas y disposiciones de las
          presentes Bases, las cuales tendrán carácter definitivo en todos los
          asuntos relacionados a esta Dinámica.
        </p>
        <p>
          <strong>2) VIGENCIA:</strong> La Dinámica tendrá vigencia a partir del
          16 de mayo de 2022 al 26 de mayo de 2022 (en adelante la “Vigencia”).
          El plazo es improrrogable, no aceptándose participaciones con
          posterioridad al plazo antes mencionado.
        </p>
        <p>
          <strong>3) AREA GEOGRÁFICA:</strong> La dinámica se encontrará vigente
          dentro de la App y en el Sitio Web, para residentes de los Estados
          Unidos Mexicanos y en las siguientes sucursales: • Tiendas Real Madrid
          Santa Fe ubicada en Avenida Vasco de Quiroga, Sport City 3800, LC 95
          Int. LC 004-005 Y 95-9, Colonia Santa Fe, Alcaldía Cuajimalpa, Código
          Postal 05348, Ciudad de México de 11:00 a 18:00 horas. • Tienda Real
          Madrid Satélite ubicada en Circuito Centro Comercia No. 2251, LC. 132
          al 135 Colonia Ciudad Satélite, C.P. 53100, Naucalpan de Juárez,
          Estado de México de 11:00 a 18:00 horas.
        </p>
        <p>
          <strong>
            4) DESCRIPCIÓN DE PROCEDIMIENTO Y MECANICA DE LA DINÁMICA:
          </strong>
        </p>
        <p>
          a) Cada Participante podrá participar ingresando a través del sitio
          web o de la App. En caso de usar la App, deberá de descargar la App a
          través de “google play” o “apple store”. En ambos casos de no estar
          registrado como miembro de “adiClub”, el Participante deberá
          registrarse como miembro del Programa “adiClub” para poder participar
          en la Dinámica el Evento.
        </p>
        <p>
          b) En caso de ingresar a través del sitio web y/o contar con la App y
          registro previo, los Participantes podrá ingresar directamente a la
          Dinámica.
        </p>
        <p>
          c) Una vez dentro de la App o del sitio web, los Participantes deberán
          registrarse en la Dinámica.
        </p>
        <p>
          d) Por lo que se refiere a los Participantes que acudan a las
          Sucursales Participantes señaladas en estas Bases, habrá 500
          (quinientos) cupones por cada Sucursal Participante, para lo cual los
          Participantes podrán acudir a cualquiera de las Sucursales y solicitar
          uno de los cupones disponibles, deberán escanear el código QR que se
          encuentre en dichas Sucursales, ingresar el código que se menciona en
          el cupón que obtendrán en las Sucursales Participantes y registrarse
          en la Dinámica.
        </p>
        <p>
          e) Una vez realizado el registro mencionado en el inciso anterior, los
          Participantes deberán de completar los 4 módulos que se encuentran en
          la Dinámica, una vez iniciados los módulos es posible salirse, mas no
          regresar al inicio de los mismos. Dentro de cada módulo existe un
          temporizador, por lo que se tomará en cuenta el mayor número de
          respuestas correctas en el menor tiempo para cada tarea asignada por
          cada módulo.
        </p>
        <p>
          <strong>El Módulo 1</strong>, consta de 8 (ocho) preguntas cada una
          con valor de 5 (cinco) puntos que dan un total de 40 (cuarenta) puntos
          entre todas, por lo que los Participantes deberán de contestar el
          mayor número de respuestas correctas en el menor tiempo posible.
        </p>
        <p>
          Al finalizar el módulo 1 se regresará al menú principal para continuar
          con el módulo 2.
        </p>
        <p>
          <strong>El Módulo 2</strong> constará de un máximo de 20 (veinte)
          puntos en el cual se mostrará una imagen con zoom predeterminado para
          desplazarse sobre la misma de tal manera que se inicie una búsqueda de
          4 elementos clave indicados por el Organizador.
        </p>
        <p>
          Una vez terminado este módulo el usuario regresará al mapa principal
          para iniciar con el módulo 3.
        </p>
        <p>
          <strong> El Módulo 3</strong> cuenta con un máximo de 60 (sesenta)
          puntos; para obtener dichos puntos, los Participantes deberán de
          contestar con la alineación correcta de los 11 (once) jugadores del
          cuadro inicial y el Director Técnico de alguno de los partidos más
          importantes del Real Madrid, para lo cual deberán elegir dentro de las
          3 (tres) opciones la respuesta correcta.
        </p>
        <p>
          Una vez terminado este módulo, se regresa al menú principal en donde
          se le indica que el 25 de mayo de 2022, los Participantes deberán de
          ingresar a la Dinámica para conocer si fueron de los 120 (ciento
          veinte) seleccionados, para formar parte de estos 120 (ciento veinte)
          seleccionados se tomará en consideración el mejor puntaje con base en
          que las tareas de cada uno de los 3 módulos hayan sido contestadas
          correctamente y en el menor tiempo posible.
        </p>
        <p>
          El 25 de mayo de 2022 aparecerá la tabla de los 120 (ciento veinte)
          participantes seleccionados adicionalmente se les notificará a los 120
          (ciento veinte) Participantes seleccionados vía correo electrónico que
          siguen participando en la Dinámica, en caso de que algún Participante
          no se encuentre en dicha tabla no seguirá activo para participar en el
          Módulo 4.
        </p>
        <p>
          Sólo los Participantes que hayan aparecido en la tabla de
          participantes seleccionados podrán participar en el Módulo 4.
        </p>
        <p>
          <strong> En el Módulo 4</strong>, los Participantes deberán crear un
          vídeo explicando y demostrando porqué son el más fan más real del Real
          Madrid y deberán adjuntar el link del vídeo en la casilla que aparece
          en el módulo 4 únicamente a partir de las 00:00 horas del día 25 de
          mayo de 2022 y hasta las 12:00 horas del del 26 de mayo de 2022,
          además deberán cargarla a su cuenta personal de Instagram el vídeo y
          etiquetar la siguiente cuenta @adidasmx.
        </p>
        <p>
          Los Participantes sólo tendrán la oportunidad de adjuntar 1 (un)
          vídeo.
        </p>
        <p>
          El 26 de mayo de 2022 a las 16:00 horas, el Organizador anunciará a
          los 8 (ocho) Participantes seleccionados cuyo vídeo a criterio del
          Organizador sea el más creativo y original en el que se demuestre
          porqué es el más fan más real del Real Madrid mediante el envío de
          correo electrónico a los mismos. Dichos participantes seleccionados
          tendrán hasta las 12:00 horas del 27 de mayo de 2022 para responder al
          correo electrónico que se les envío y confirmar su asistencia al Brand
          Center Madero ubicado en en la calle Isabel la Católica número 25
          esquina con Francisco I. Madero, colonia Centro, Alcaldía Cuauhtémoc,
          código postal 06000, en la Ciudad de México (el Brand Center) el día
          28 de mayo de 2022 a las 10:30 am para continuar con la Dinámica.
        </p>
        <p>
          Los 8 (ocho) Participantes seleccionados del módulo 4, deberán acudir
          al Brand Centerel día 28 de mayo de 2022 a las 10:30 am y deberán
          deberán cumplir con todas las medidas de seguridad y de higiene (EPP:
          Cubrebocas, gel anti bacterial) y respetando la sana distancia para
          llevar a cabo las siguientes competencias y de esta manera obtener al
          Participante Ganador del Premio:
        </p>
        <p>
          La primera competencia se realizará en el "futbolito" ubicado en el
          Brand Center, para lo cual se llevarán a cabo 4 (cuatro) partidos y
          los Participantes en formato de 1 (uno) contra 1 (uno) se enfrentarán
          en partidos con duración de 2 (dos) minutos cada uno. En caso de
          empate dentro de los partidos de 2 (dos) minutos se jugará 1 (un)
          minuto más donde el ganador será el primero en anotar.
        </p>
        <p>
          Una vez finalizados los 4 (cuatro) partidos se clasificará a los
          Participantes con base en la mayor cantidad de goles anotados por cada
          Participante en su respectivo partido. Siendo el participante ganador
          de esta etapa, aquel que tenga el mayor número de goles anotados en su
          respectivo partido.
        </p>
        <p>
          La siguiente, y final, competencia se realizará dentro del área de
          "cascarita time" del Brand Center. Se realizarán 4 (cuatro) partidas
          uno contra uno, siendo el ganador el jugador con mayor puntaje de cada
          partida de 2 (dos) minutos. En caso de haber empate, habrá una partida
          adicional de 2 (dos) minutos hasta haber un ganador.
        </p>
        <p>
          Los partidos se realizarán de acuerdo a la clasificación de los
          participantes, siendo el número 1 el que realice el mayor número de
          goles en el “futbolito”, por lo que los participantes competirán de la
          siguiente manera:
        </p>
        <p>
          <strong> a.</strong> el lugar número 1 contra el lugar número 8 de la
          etapa del “futbolito”
        </p>
        <p>
          <strong>b. </strong>el lugar número 2 contra el lugar número 7 de la
          etapa del “futbolito”
        </p>
        <p>
          <strong> c.</strong> el lugar número 3 contra el lugar número 6 de la
          etapa del “futbolito”
        </p>
        <p>
          <strong> d.</strong> el lugar número 4 contra el lugar número 5 de la
          etapa del “futbolito”
        </p>
        <p>
          Una vez finalizada esta ronda se clasificará a los participantes del 1
          al 4 siendo el 1 el jugador con mayor puntaje y así sucesivamente
          hasta el 4, eliminando a los últimos 4 lugares, de tal forma, que la
          siguiente ronda se jugará bajo las mismas reglas de la dinámica
          anterior:
        </p>
        <p>
          <strong>a.</strong> el lugar número 1 contra el lugar número 4
        </p>
        <p>
          {" "}
          <strong>b.</strong> el lugar número 2 contra el lugar número 3
        </p>
        <p>
          Finalizada esta ronda, los dos ganadores de ambas partidas disputarán
          una final con la misma dinámica.
        </p>
        <p>
          El participante que gane esta última partida será el Participante
          Ganador y será acreedor al Premio de conformidad con lo que se indica
          en las presentes Bases.
        </p>
        <p>
          <strong> f)</strong> El Participante ganador podrá llevar un 1 (un)
          acompañante, mismo que deberá ser mayor de edad.
        </p>
        <p>
          <strong>g)</strong> El Participante Ganador y su acompañante deberán
          cumplir con todas las medidas de seguridad y de higiene (EPP:
          Cubrebocas, gel anti bacterial) y respetando la sana distancia,
          realización de pruebas PCR y/o cualquier otra que sea solicitada,
          exhibición de certificado de vacunación y demás requisitos y
          disposiciones de viaje y estancia en España, acceso a los lugares
          incluidos en el Premio, así como los exigidos tanto por el aeropuerto
          y aerolínea correspondiente
        </p>
        <p>
          <strong>h)</strong> El Organizador informa que contará en total con 1
          (un) viaje doble disponible
        </p>
        <p>
          <strong>i)</strong> Se contará con solo 1 (una) oportunidad para
          participar en la dinámica por Participante.
        </p>
        <p>
          <strong>j)</strong> El Participante ganador no podrá declinar su
          calidad de ganador o transferirlo a un tercero.
        </p>
        <p>
          De acuerdo con el párrafo anterior, los Participantes liberan de
          cualquier responsabilidad al Organizador, así como a cualquiera de sus
          filiales, subsidiarias, sociedades o empresas de cualquier daño,
          perjuicio, enfermedad o cualquier otra situación que derive directa o
          indirectamente de su aceptación voluntaria para participar en la
          Dinámica.
        </p>
        <p>
          {" "}
          <strong> 1) CRITERIOS DE DESCALIFICACIÓN:</strong> Quedarán fuera de
          la Dinámica los Participantes que:
        </p>
        <p>
          <strong>a)</strong> No reúnan las condiciones para participar en la
          Dinámica o bien aquéllos que no cumplan con cualquiera de los términos
          y condiciones establecidos en las presentes Bases.
        </p>
        <p>
          <strong> b)</strong> Los Participantes que participen de la Dinámica a
          través de registros con datos de terceros o falsos quedarán
          descalificados. Es necesario que los datos personales proporcionados
          por los Participantes sean veraces y completos, en caso de que los
          mismos sean inciertos e incompletos, el Organizador no tendrá la
          obligación de entregar el PREMIO al Participante que resulte ganador.
        </p>
        <p>
          <strong>c)</strong> Aquellos Participantes que no formen parte del
          Programa.
        </p>
        <p>
          <strong>d)</strong> Aquellos Participantes que intenten participar y/o
          darse de baja y registrarse más de 1 (una) vez.
        </p>
        <p>
          <strong>e)</strong> Aquellos Participantes que sean seleccionados para
          continuar la Dinámica en el Brand Center y no confirmen su asistencia
          por los medios y en el plazo que se indica en el número 4) de estas
          Bases.
        </p>
        <p>
          <strong> f)</strong> Aquellos participantes que no realicen la
          etiqueta @adidasmx en su cuenta de Instagram de conformidad con lo que
          se indica en estas Bases.
        </p>
        <p>
          <strong> 6) SELECCIÓN DE GANADORES:</strong> El Organizador informa
          que solo habrá 1 (un) Participante ganador. La selección se realizará
          en función de lo que se indica en el número 4) de estas Bases. Para el
          caso de que el Participante ganador no cumpla con los requisitos
          establecidos en las presentes Bases o bien por causas provenientes por
          caso fortuito o fuerza mayor le sea imposible acceder al PREMIO, el
          Organizador no será responsable de la pérdida de este.
        </p>
        <p>
          Si de conformidad con estas Bases, el Participante ganador pierde su
          derecho a gozar el PREMIO, el mismo se otorgará al Participante que
          haya obtenido el segundo lugar en la última etapa de la Dinámica,
          según lo determinado en el número 4) de estas Bases, por lo que tendrá
          el carácter de Participante ganador suplente.
        </p>
        <p>
          <strong>PREMIO:</strong> El Organizador informa que solo podrá existir
          1 (un) Participante ganador, que podrá designar a 1 (un) acompañante,
          siempre que este sea mayor de edad y cumpla con los requisitos
          establecidos en las presentes Bases.
        </p>
        <p>
          El PREMIO consistirá en un viaje doble a Madrid, España durante el mes
          de septiembre de 2022, para lo cual el Organizador se obliga a
          confirmar al Participante Ganador vía correo electrónico la fecha de
          dicho viaje a más tardar el 31 de julio de 2022. El PREMIO incluye lo
          siguiente:
        </p>
        <p>
          (a) 2 (dos) pasajes aéreos redondos clase turista saliendo desde la
          Ciudad de México hacia Madrid y de Madrid, España a la Ciudad de
          México en la aerolínea designada por el Organizador;
        </p>
        <p>
          {" "}
          (b) 2 noches de hospedaje en habitación en Hotel designado por el
          Organizador
        </p>
        <p>
          El Organizador informa que solo habrá 1 (un) Participante ganador. El
          PREMIO es intransferible, El Participante que resulte ganador deberá
          hacer exigible su PREMIO únicamente en las fechas que El Organizador
          indique.
        </p>
        <p>
          <strong>El premio NO INCLUYE:</strong>
        </p>
        <p>
          • Ningún traslado terrestre, aéreo o de ninguna otra especie o
          naturaleza, que no sea especificado en la descripción del PREMIO. En
          caso de que el Participante ganador no vivan en la Ciudad de México,
          el traslado a la misma correrá por su cuenta.
        </p>{" "}
        <p>• Alojamiento no especificado en la descripción del PREMIO.</p>
        <p>
          • Alimentos y Bebidas no especificados en la descripción del PREMIO.{" "}
        </p>
        <p>
          {" "}
          • Seguros adicionales no especificados en la descripción del PREMIO.{" "}
        </p>
        <p>• Viáticos o pago de gastos de ningún tipo.</p>
        <p>• Impuestos de ningún tipo.</p>
        <p> • Certificado de prueba COVID PCR, o ninguna otra.</p>
        <p>• Pruebas para la detección de virus SARS-CoV-2 2 de ningún tipo.</p>
        <p>• Exámenes Médicos.</p>
        <p>
          • Ningún gasto extra que el Participante ganador o su acompañante
          realicen en virtud de ejercer su derecho al PREMIO.{" "}
        </p>
        <p>
          Si el PREMIO no pudiera asignarse en virtud de la falta de algún
          requisito de los presentes términos y condiciones por causas derivadas
          de caso fortuito o fuerza mayor será asignado al Participante ganador
          suplentes, y en caso de que éste tampoco pueda acceder al mismo,
          quedará en propiedad y a disposición del Organizador.
        </p>
        <p>
          En caso de no cumplir el Participante ganador y/o su acompañante con
          cualquiera de los requisitos de esta cláusula y/o cualquiera de los
          requisitos de las presentes Bases, caducará su carácter de ganador,
          sin que le asista derecho al reclamo del PREMIO y/o compensación o
          indemnización alguna.
        </p>
        <p>
          El PREMIO no es sustituible, canjeable por dinero u otros bienes,
          productos o servicios ni podrá ser cedido o transferido a terceros. El
          Participante ganador y/o su acompañante serán responsables por
          cualquier otro gasto, impuesto y/o tasa no incluida expresamente en
          estas Bases como a cargo del Organizador. Todos los costos, gastos,
          impuestos y/o tasas no mencionadas como incluidos se entienden
          expresamente excluidos, sin que los mismos sean responsabilidad del
          Organizador o de cualquier otra empresa del Grupo adidas.
        </p>
        <p>
          El Organizador se reserva el derecho de sustituir el PREMIO por uno de
          igual o mayor valor en el caso que, por circunstancias ajenas a la
          voluntad del Organizador, el PREMIO no pueda ser proporcionado al
          ganador.
        </p>
        <p>
          <strong>7) NOTIFICACIONES AL PARTICIPANTES GANADORES:</strong>
        </p>
        <p>
          El Organizador notificará al Participante su calidad de ganador el 28
          de mayo de 2022 de manera presencial en el Brand Center.
        </p>
        <p>
          Para efectos de localización del ganador el Organizador no será
          responsable por algún error que pueda surgir en los datos
          proporcionados por el Participante ganador.
        </p>
        <p>
          En caso de que el Participante no responda su aceptación del PREMIO
          las condiciones de entrega de dicho PREMIO en tiempo y forma a la
          notificación, perderá irrevocablemente su derecho al mismo. La
          conservación del correo electrónico y número de teléfono registrados
          correrán bajo la exclusiva responsabilidad de cada Participante, por
          lo que en caso de extraviarlos o sean utilizados por algún tercero, el
          Organizador quedará relevado de toda responsabilidad respecto del
          PREMIO que corresponda a la Dinámica.
        </p>
        <p>
          <strong>8) PROCESO PARA RECEPCIÓN DEL PREMIO:</strong>
        </p>
        <p>
          El PREMIO será puesto a disposición del Participante ganador después
          de que se les haya notificado y éste estos lo hayan aceptado vía
          correo electrónico con la confirmación de vuelo y hospedaje
          correspondiente acorde con las fechas y características que le
          comunique el Organizador. Si el adjudicatario no concurriese a retirar
          el PREMIO en la fecha y hora establecida, o no logra acreditar su
          identificación y/o reunir los requisitos de Participante para
          recogerlo, perderá su derecho irrevocablemente a recibirlo.
        </p>
        <p>
          El participante ganador y su acompañante deberán cumplir con los
          siguientes requisitos para retirar los Premios:
        </p>
        <p>
          (i) Exhibir el original de su documento oficial (pasaporte) de
          identificación vigente que compruebe que son mayores de 18 (dieciocho)
          años.
        </p>
        <p>
          (ii) Deberá acreditar su condición de Participante que reúnan los
          requisitos establecidos para Participantes establecidos en estas Bases
        </p>
        <p>
          (iii) Firmar la carta responsiva correspondiente que el Organizador le
          indique.
        </p>
        <p>
          (iv) Deberá de cumplir con todos los requisitos sobre las
          disposiciones de viaje y estancia en España, así como de acceso al
          aeropuerto y todos los requisitos exigidos por la aerolínea
          correspondiente.
        </p>
        <p>
          En caso de no cumplir el ganador con cualquiera de los requisitos de
          esta cláusula y/o cualquiera de los términos y condiciones de las
          presentes Bases, caducará su carácter de ganador, sin que le asista
          derecho al reclamo del PREMIO y/o compensación o indemnización alguna
        </p>
        <p>
          <strong>Logística y condiciones para la entrega del Premio:</strong> 
        </p>
        <p>
          El PREMIO es único, personal e intransferible, en caso de que el
          Participante ganador no cumplan con los requisitos antes establecidos
          para la entrega del mismo, el PREMIO se dejará a consideración del
          Organizador.
        </p>
        <p>
          Los Participantes podrán comunicarse con el Organizador al correo
          electrónico: soy@fanmasrealdelreal.com para cualquier duda relacionada
          con la Dinámica.
        </p>
        <p>
          En caso de no cumplir el Participante ganador con cualquiera de los
          requisitos de esta cláusula y/o cualquiera de los términos y
          condiciones de las presentes Bases, caducará su carácter de ganador,
          sin que le asista derecho a reclamo y/o compensación o indemnización
          alguna.
        </p>
        <p>
          <strong> 9) LEGALES:</strong>
        </p>
        <p>
          El Organizador, sus filiales, subsidiarias, sociedades o empresas
          integrantes del Grupo adidas y licenciantes, no serán responsables por
          daños o perjuicios que pudieren sufrir los Participantes o terceros,
          con motivo y/u ocasión de la participación en la presente Dinámica y/o
          del uso del PREMIO y/o Productos adidas, y/o el traslado a cumplir con
          los requisitos de estos términos y condiciones, declinando todo tipo
          de responsabilidad contractual y/o extracontractual frente a los
          Participantes ganadores y/o acompañantes y/o sus sucesores, lo que es
          aceptado incondicionalmente por cada Participante.
        </p>
        <p>
          El sólo hecho de participar en la Dinámica y la aceptación a estas
          Bases, implicará la autorización del respectivo Participante al
          Organizador a publicar su imagen, por los medios y formas de
          comunicación que el Organizador crea conveniente, a su exclusivo
          criterio, para fines promocionales, sin derecho a compensación o
          remuneración alguna. Los Participantes también autorizan al
          Organizador, su controladora, filiales, subsidiarias, sociedades o
          empresas integrantes del Grupo adidas y licenciantes a tratar sus
          datos personales a fin de establecer perfiles determinados con fines
          promocionales, comerciales o publicitarios; o para establecer hábitos
          de consumo acorde con su Aviso de Privacidad mencionado en el Numeral
        </p>
        <p>
          <strong>11) de las presentes Bases.</strong>
        </p>
        <p>
          El Organizador se reserva el derecho a realizar la acción
          publicitaria, de prensa, Dinámica, publicación y difusión que
          consideren conveniente de los Participantes sean ganadores o no, y de
          su imagen y/o voces, como así también de toda notificación inherente a
          estos términos y condiciones, a fin de otorgar la mayor transparencia
          a la presente Dinámica. Con la sola participación en la Dinámica y la
          aceptación de las bases, los Participantes sean ganadores o no otorgan
          su consentimiento expreso al Organizador, su controladora, sus
          filiales, subsidiarias, sociedades o empresas integrantes del Grupo
          adidas y licenciantes para el uso de su imagen y voz (textos, fotos y
          videos), por lo que en ningún caso el Participante podrá revocar su
          consentimiento expreso respecto del derecho de uso concedido. Dicho
          consentimiento no estará sujeto a una compensación o remuneración de
          ningún tipo. En tal virtud el o los Participantes ganadores se obligan
          a mantener en paz y a salvo a el Organizador, su controladora, sus
          filiales, subsidiarias, sociedades o empresas integrantes del Grupo
          adidas y licenciantes, agentes, contratistas, empleados y accionistas
          frente a cualquier reclamo que pueda provenir de cualquier tercero que
          alegue tener la titularidad de los derechos concedidos.
        </p>
        <p>
          El Organizador, su controladora, sus filiales, subsidiarias,
          sociedades o empresas integrantes del Grupo adidas y licenciantes
          podrán usar dichas imágenes, textos, voz, fotografías y datos por los
          medios publicitarios y de comunicación que el Organizador disponga. En
          virtud de esta autorización, el Participante ganador no tendrá derecho
          a indemnización, pago o contraprestación alguna por la difusión y/o
          utilización de su imagen mencionada precedentemente. Por el solo hecho
          de participar, todos los Participantes aceptan conocer y aceptar los
          presentes términos y condiciones. Los términos y condiciones serán
          publicados durante la vigencia de la Dinámica en la App, en el Sitio
          Web y las Sucursales Participantes.
        </p>
        <p>
          La responsabilidad del Organizador, su controladora, sus filiales,
          subsidiarias, sociedades o empresas integrantes del Grupo adidas y
          licenciantes, finaliza por todo concepto con la puesta a disposición
          del PREMIO del Participantes ganador.
        </p>
        <p>
          El Organizador podrá modificar las presentes Bases, siempre y cuando
          no se altere la esencia de la Dinámica y no implique disminución
          alguna de la cantidad de premios de esta, debiendo notificar, en forma
          previa, dicha/s modificación/es a los Participantes por los mismos
          medios de comunicación utilizados oportunamente para su difusión, sin
          que ello genere derecho alguno o reclamo por parte de los
          Participantes.
        </p>
        <p>
          No podrán participar en la Dinámica empleados del Organizador, así
          como de socios y/o proveedores de este tales como agencias de
          publicidad, agencias digitales, relaciones públicas, programadores,
          accionistas, directores, etc. Las restricciones referidas aplican
          también para familiares de hasta segundo grado de los empleados,
          socios y proveedores referidos en los puntos que anteceden.
        </p>
        <p>
          Los Participantes aceptan que no realizarán ninguna declaración o
          comentario públicos, a través de cualquier medio incluyendo redes
          sociales cualquiera que ésta sea, en ningún sentido, que sea
          peyorativo o de mala fe, que afecte o comprometa de alguna manera la
          imagen de la marca adidas o sus Productos.
        </p>
        <p>
          En caso de fuerza mayor y/o caso fortuito que genere alguna
          imposibilidad para llevar a cabo la Dinámica o para continuar con la
          misma, el Organizador podrá suspenderla definitiva o transitoriamente,
          como así también introducir las modificaciones pertinentes,
          comunicando dicha circunstancia a los Participantes por los mismos
          medios de comunicación utilizados oportunamente para el lanzamiento de
          la Dinámica, sin que ello genere derecho alguno o reclamo por parte de
          los Participantes.
        </p>
        <p>
          <strong>10) PUBLICIDAD:</strong>{" "}
        </p>
        <p>
          Estas Bases podrán ser consultadas en forma libre y gratuita en la
          App, en el Sitio Web y en las Sucursales Participantes durante la
          vigencia de la Dinámica.
        </p>
        <p>
          <strong>
            11) AVISO DE PRIVACIDAD SIMPLIFICADO: adidas de México, S.A. de C.V.
          </strong>
        </p>
        <p>
          adidas de México, S.A. de C.V. y/o “el Organizador”, es responsable
          del tratamiento de sus Datos personales, los cuales utilizará para las
          siguientes finalidades necesarias: adquisición de mercancía, brindarle
          servicios, dar cumplimiento a obligaciones contraídas, establecimiento
          de relaciones a través de proveedores de servicios, participación en
          promociones, dinámicas y/o activaciones, realizar investigaciones a
          los proveedores de adidas México, de identificación, uso de la app de
          adidas, crediticios, controles para evitar fraude, analizar su forma
          de interactuar con nuestra marca, dar cumplimiento a obligaciones
          regulatorias; y para las siguientes finalidades secundarias: Informar
          nuevos servicios, evaluación de servicio, remarketing, análisis de
          hábitos de consumo, mercadotécnicos, publicitarios, evaluación de
          calidad y prospección comercial.
        </p>
        <p>
          En cualquier momento podrá revocar su consentimiento sobre el
          tratamiento de sus Datos para las finalidades secundarias, siguiendo
          el proceso señalado en el apartado VI incluido en nuestro aviso de
          privacidad integral. Para conocer nuestro aviso de privacidad
          integral, visite nuestra página:{" "}
          <a
            href="https://www.adidas.mx/help-topics-privacypolicy.html"
            className="text-purple-primary"
          >
            https://www.adidas.mx/help-topics-privacypolicy.html
          </a>
        </p>
        <p>
          {" "}
          Por el solo hecho de participar en la Dinámica, los Participantes
          aceptan conocer el Aviso de Privacidad Integral.
        </p>
      </div>
    </>
  );
}

export default TermsText;
