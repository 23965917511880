import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../ctx/Auth";

export default function RequireAuth({ children }) {
  const { authUser, pending } = useContext(AuthContext);

  if (pending) {
    return <></>;
  }

  if (!authUser) {
    return <Navigate to="/registro" />;
  }

  return <>{children}</>;
}