import { createContext, useContext, useEffect, useState } from "react";
import { db } from "../util/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { useAuth } from "./Auth";

export const UserContext = createContext();

export function useUser() {
  const context = useContext(UserContext);
  return context;
}

export function UserProvider(children) {
  const [userData, setUserData] = useState();
  const [pending, setPending] = useState(true);
  const user = useAuth()

  useEffect(() => {
    const unsuscribe = onSnapshot(
      doc(db,"users", user.authUser.uid),
      (doc) => {
        let snap = doc.data()
        setUserData(snap);
        setPending(false);
      }
    );
    return () => unsuscribe();
  }, [user.authUser.uid]);

  if (pending) {
    return <></>;
  }

  return <UserContext.Provider value={{ userData }} {...children} />;
}