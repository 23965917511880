function Aside({ children, background }) {
  return (
    <aside
      className={`fixed right-0 top-0 w-72 h-screen ${background} 
      justify-center items-center z-20 hidden lg:flex`}
    >
      {children}
    </aside>
  );
}

export default Aside;
