import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../util/firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";

export const AuthContext = createContext();

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

export function AuthProvider(element) {
  const [authUser, setAuthUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    const unsuscribe = onAuthStateChanged(auth, (user) => {
      if (user?.email === null) {
        signOut(auth);
        return;
      }
      setAuthUser(user);
      setPending(false);
    });
    return () => unsuscribe();
  }, []);

  const logOut = () => signOut(auth);

  return (
    <AuthContext.Provider value={{ authUser, pending, logOut }} {...element} />
  );
}