function Card({ children, padding, valign }) {
  return (
    <>
      <div
        className={`flex flex-col bg-opacity-90 bg-white shadow-lg w-full  
                  max-w-card h-auto items-center ${padding || ""} ${
          valign || ""
        }`}
      >
        {children}
      </div>
    </>
  );
}

export default Card;
