import { useState, useEffect } from "react";

function Alert({ type, message, onClick }) {
  const [variant, setVariant] = useState("");

  useEffect(() => {
    if (type === "success") {
      setVariant(" border border-green-200 bg-green-100 text-green-500");
    } else if (type === "error") {
      setVariant("border border-red-200 bg-red-100 text-red-500 cursor-pointer");
    }
  }, [type]);

  return (
    <div className={`border-solid text-center py-3 w-full mt-3 mb-4 box-border ${variant}`} onClick={onClick}>
      {message}
    </div>
  );
}

export default Alert;
