import Aside from "./components/Aside";
import AsideLogo from "./img/rm-logo-completo-blanco.svg";
import Container from "./components/Container";
import Card from "./components/Card";
import TermsText from "./components/TermsText";
import { useNavigate } from "react-router-dom";
import Button from "./components/Button";
import Logo from "./components/Logo";

function Terms() {
  const navigate = useNavigate();
  return (
    <>
      <Aside background="bg-purple-primary">
        <img src={AsideLogo} alt="logo" className="w-2/4  mb-10 mx-auto" />
      </Aside>
      <Logo />
      <Container image="bg-login-image" stretch="items-stretch">
        <Card padding="px-3 py-6" valign="justify-center">
          <TermsText />
          <Button onClick={() => navigate("/registro")}>Regresar</Button>
        </Card>
      </Container>
    </>
  );
}

export default Terms;
