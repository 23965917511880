import { useEffect, useRef, useState } from "react";
import logoAdidas from "../img/logo-adidas.svg";

function InputRegister({ placeholder, type, name, margin, autofocus }) {
  const inputRef = useRef(null);
  const [marginClass, setMarginClass] = useState("");

  useEffect(() => {
    if (autofocus) {
      inputRef.current.focus();
    }
  }, [autofocus]);

  useEffect(() => {
    if (!margin) {
      setMarginClass("");
    } else {
      setMarginClass(margin);
    }
  }, [margin]);

  return (
    <div className={`flex bg-purple-primary ${marginClass}`}>
      <div className="w-12 h-12 flex  bg-purple-primary items-center justify-center">
        <img src={logoAdidas} alt="Adidas" className="w-9" />
      </div>
      <input
        placeholder={placeholder}
        name={name}
        type={type}
        className="autofill:bg-purple-primary  hover:bg-purple-primary active:bg-purple-primary
            focus:bg-purple-primary-500 bg-purple-primary p-3 text-white italic
             placeholder-white focus:outline-none focus:shadow-outline flex-grow"
        ref={inputRef}
        required
      />
    </div>
  );
}

export default InputRegister;
