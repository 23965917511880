import Container from "./components/Container";
import Aside from "./components/Aside";
import AsideLogo from "./img/rm-adidas-logo-blanco.svg";
import Lightbox from "./components/Lightbox";
import { auth } from "./util/firebase";
import { signOut } from "firebase/auth";
import { useUser } from "./ctx/User";
import Sorry from "./components/Sorry";
import Finalist from "./components/Finalist";
import Closed from "./components/Closed";

function Hold() {
  const { userData: USER } = useUser();

  return (
    <>
      <Aside background="bg-purple-primary">
        <img src={AsideLogo} alt="logo" className="w-4/6  mb-10 mx-auto" />
      </Aside>
      <Container image="bg-home-image"></Container>
      <Lightbox visible={true}>
        {USER.finalist ? (
          <Closed onClick={() => signOut(auth)} />
        ) : (
          <Sorry onClick={() => signOut(auth)} />
        )}
      </Lightbox>
    </>
  );
}

export default Hold;
