import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
function Checkbox({ label, name, value, margin, link }) {
  const [href, setHref] = useState("");
  const [marginClass, setMarginClass] = useState("");
  const [hover, setHover] = useState("");

  useEffect(() => {
    if (link) {
      setHref(link);
      setHover("hover:text-underline hover:cursor-pointer");
    }
  }, [link]);

  useEffect(() => {
    if (margin) {
      setMarginClass(margin);
    }
  }, [margin]);

  return (
    <div className={marginClass}>
      <label
        className={`italic text-purple-primary text-xs flex items-center ${hover}`}
      >
        <input
          name={name}
          type="checkbox"
          value={value}
          className="appearance-none w-5 h-5 rounded-none hover:cursor-pointer
                  border-solid border border-purple-primary text-purple-primary  checked:bg-purple-primary"
          required
        />
        <span className="ml-3 leading-tight">
          {label === "age"
            ? "Soy mayor de edad y cuento con pasaporte vigente"
            : ""}

          {label === "terms" ? (
            <>
              Acepto los
              <Link to={href}>
                <span className="underline underline-offset-4">
                  {" "}
                  Términos y Condiciones
                </span>
              </Link>{" "}
              y el{" "}
              <Link to={href}>
                <span className="underline underline-offset-4">
                  Aviso de privacidad
                </span>
              </Link>
            </>
          ) : (
            ""
          )}
        </span>
      </label>
    </div>
  );
}

export default Checkbox;
