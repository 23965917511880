import { useEffect, useRef } from "react";

function Input({ placeholder, type, name, autofocus }) {
  const inputRef = useRef(null);

  useEffect(() => {
    if (autofocus) {
      inputRef.current.focus();
    }
  }, [autofocus]);

  return (
    <input
      placeholder={placeholder}
      name={name}
      type={type}
      className="appearance-none bg-purple-primary w-full p-3 text-white italic 
      autofill:bg-blue-primary placeholder-white focus:outline-none focus:shadow-outline focus:bg-purple-primary-500
       text-center"
      ref={inputRef}
      required
    />
  );
}

export default Input;
