import Logo from "../img/rm-adidas-logo-blanco.svg";

function Closed({ onClick }) {
  return (
    <>
      <div className=" w-full max-w-card pb-12 lg:pb-0">
        <div
          className="flex py-12 px-6 bg-opacity-95 bg-purple-primary 
          shadow-lg w-full max-w-card  items-center justify-center flex-col"
        >
          <img src={Logo} alt="" className="w-4/6" />
          <h1 className="text-white text-xl mt-6 text-center">
            <strong>Ha finalizado la Segunda Etapa del Fan más Real del Real</strong>
          </h1>
          <ol className="text-white text-sm mt-3 text-center">
            <li>
              Mantente al pendiente de tu correo para saber si fuiste uno de los 8 finalistas y avanzaste a la ronda final. 
              Los resultados los notificaremos el 26 de mayo, vía correo electrónico.
            </li>
            <li className="mt-3">¡No olvides revisar en spam!</li>
          </ol>
          <button
            onClick={onClick}
            className="transition ease-linear mt-6 px-8 py-2 appearance-none border-solid border border-white text-white italic 
            hover:bg-white hover:text-purple-primary"
          >
            Salir
          </button>
        </div>
      </div>
    </>
  );
}

export default Closed;