import React, { useEffect, useState } from "react";

function Lightbox({ children, visible }) {
  const [display, setDisplay] = useState("");
  useEffect(() => {
    if (visible) {
      setDisplay("flex");
      document.body.style.overflow = "hidden";
    } else {
      setDisplay("hidden");
      document.body.style.overflow = "auto";
    }
  }, [visible]);
  return (
    <>
      {/* <div
        className={`bg-black bg-opacity-80 w-full h-full fixed top-0 
        left-0 z-40 overflow-y-auto lg:items-center justify-center ${display}`}
      > */}
      <div
        className={`bg-purple-secondary bg-opacity-90 w-full h-full fixed top-0 
        left-0 z-40 overflow-y-auto lg:items-center justify-center backdrop-blur-sm ${display}`}
      >
        <div className="py-12">{children}</div>
      </div>
    </>
  );
}

export default Lightbox;
