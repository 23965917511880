function Container({ children, image, stretch }) {
  return (
    <div
      className={`flex w-full h-full min-h-screen justify-center  
      items-center bg-cover bg-center py-6 box-border px-2 bg-fixed 
      ${stretch || ""} ${image}`}
    >
      {children}
    </div>
  );
}

export default Container;
