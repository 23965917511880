import rmLogoDesktop from "../img/rm-logo-desktop.svg";

function Logo() {
  return (
    <div className="fixed left-0 bottom-0 w-80 z-40 hidden lg:block ">
      <img src={rmLogoDesktop} alt="logo" className="w-full" />
    </div>
  );
}

export default Logo;
