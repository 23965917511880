import { Routes, Route } from "react-router-dom";
import { UserProvider } from "./ctx/User";
import Home from "./Home";
import Login from "./Login";
import Register from "./Register";
import RequireAuth from "./components/RequireAuth";
import GameOnePage from "./GameOnePage";
import GameTwoPage from "./GameTwoPage";
import GameThreePage from "./GameThreePage";
import Terms from "./Terms";
import "./sass/styles.sass";
import ResetPassword from "./ResetPassword";
import SignOut from "./components/SignOut";
import Hold from "./Hold";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <UserProvider>
                <Hold />
              </UserProvider>
            </RequireAuth>
          }
        />
        {/* <Route
          path="/"
          element={
            <RequireAuth>
              <UserProvider>
                <SignOut />
                <Home />
              </UserProvider>
            </RequireAuth>
          }
        />
        <Route
          path="/responde-correctamente"
          element={
            <RequireAuth>
              <UserProvider>
                <GameOnePage />
              </UserProvider>
            </RequireAuth>
          }
        />
        <Route
          path="/encuentra-los-objetos"
          element={
            <RequireAuth>
              <UserProvider>
                <GameTwoPage />
              </UserProvider>
            </RequireAuth>
          }
        />
        <Route
          path="/selecciona-correctamente"
          element={
            <RequireAuth>
              <UserProvider>
                <GameThreePage />
              </UserProvider>
            </RequireAuth>
          }
        /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/registro" element={<Register />} />
        <Route
          path="/terminos-y-condiciones-y-aviso-de-privacidad"
          element={<Terms />}
        />
        <Route path="/recuperar-contrasena" element={<ResetPassword />} />
      </Routes>
    </div>
  );
}

export default App;
