import { useEffect, useState } from "react";
import { useAuth } from "./ctx/Auth";
import { useNavigate } from "react-router-dom";
import { auth, sendPasswordResetEmail } from "./util/firebase";
import Container from "././components/Container";
import Card from "./components/Card";
import Input from "./components/Input";
import rmLogo from "./img/rm-logo-completo.svg";
import rmLogoBlanco from "./img/rm-logo-completo-blanco.svg";
import Aside from "./components/Aside";
import Logo from "./components/Logo";
import Button from "./components/Button";
import Alert from "./components/Alert";

function ResetPassword() {
  const { authUser } = useAuth();
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setAlertMessage(null);
    setAlert(false);
    const { email } = e.target.elements;
    try {
      await sendPasswordResetEmail(auth, email.value);
      setAlert("success");
      setAlertMessage("Instrucciones enviadas a tu correo");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAlert("error");
      if (error.code === "auth/user-not-found") {
        setAlertMessage("Email no encontrado");
      }
      if (error.code === "auth/invalid-email") {
        setAlertMessage("Email inválido");
      }
      if (error.code === "auth/too-many-requests") {
        setAlertMessage("Demasiados intentos, intenta más tarde");
      } else {
        setAlertMessage(error.message);
      }
    }
  }

  useEffect(() => {
    if (authUser) navigate("/");
  }, [authUser, navigate]);

  return (
    <>
      <Aside background="bg-purple-primary">
        <img src={rmLogoBlanco} alt="logo" className="w-2/4  mb-10 mx-auto" />
      </Aside>
      <Logo />
      <Container image="bg-login-image" stretch="items-stretch">
        <Card padding="px-3 py-6" valign="justify-center">
          <form className="w-full" onSubmit={handleSubmit}>
            <img
              src={rmLogo}
              alt="El fan más real del Real Madrid"
              className="w-4/5  mb-10 mx-auto"
            />
            <div className="mb-4">
              <Input
                placeholder="Ingresa tu email"
                type="text"
                name="email"
                autofocus={true}
              />
            </div>
            <div className="flex">
              <Button variant="fill" type="submit" disabled={loading}>
                {loading ? "Verificando..." : "Recuperar Contraseña"}
              </Button>
            </div>
            {alert && <Alert type={alert} message={alertMessage} />}
          </form>
          <div className="flex w-full mt-12 items-center  justify-around">
            <div className="text-center italic text-purple-primary">
              Iniciar sesión
            </div>
            <Button onClick={() => navigate("/login")}>Login</Button>
          </div>
        </Card>
      </Container>
    </>
  );
}

export default ResetPassword;
