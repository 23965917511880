import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "./util/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  Timestamp,
} from "firebase/firestore";

import { useAuth } from "./ctx/Auth";
import Container from "././components/Container";
import Card from "./components/Card";
import InputRegister from "./components/InputRegister";
import Aside from "./components/Aside";
import Logo from "./components/Logo";
import Button from "./components/Button";
import Alert from "./components/Alert";
import rmLogo from "./img/rm-logo-completo.svg";
import AsideLogo from "./img/rm-logo-completo-blanco.svg";
import Checkbox from "./components/Checkbox";
import { shema } from "./util/shema";

function Register() {
  const { authUser } = useAuth();
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (authUser) {
      navigate("/");
    }
  }, [authUser, navigate]);

  function verifyAdiclub(data) {
    if (data.length === 0) return false;
    if (data[0].used) return false;
    return data[0].id;
  }

  async function getAdiclub(code) {
    const codesRef = collection(db, "codes");
    const q = query(
      codesRef,
      where("public", "==", true),
      where("code", "==", code)
    );
    const querySnapshot = await getDocs(q);
    let data = [];
    querySnapshot.forEach((doc) => {
      let snap = doc.data();
      data.push({ ...snap, id: doc.id });
    });
    return data;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setAlertMessage(false);
    setAlert(false);
    const { name, email, password, phone, adiclub, confirmPassword } =
      event.target.elements;

    const userData = {
      name: name.value,
      email: email.value,
      phone: phone.value,
      adiclub: adiclub.value,
      createdAt: Timestamp.now(),
      ...shema,
    };

    const adiclubSnap = await getAdiclub(adiclub.value);
    const adiclubId = verifyAdiclub(adiclubSnap);

    if (!adiclubId) {
      setAlertMessage("Código inválido o en uso.");
      setLoading(false);
      setAlert("error");
      return;
    }

    if (password.value !== confirmPassword.value) {
      setAlertMessage("El password no coincide.");
      setLoading(false);
      setAlert("error");
      return;
    }

    if (!/^\d+$/.test(phone.value)) {
      setAlertMessage("El número de teléfonico debe contener solo números.");
      setLoading(false);
      setAlert("error");
      return;
    }

    try {
      const credentials = await createUserWithEmailAndPassword(
        auth,
        email.value,
        password.value
      );
      await setDoc(doc(db, "users", credentials.user.uid), userData);
      await updateDoc(doc(db, "codes", adiclubId), {
        used: true,
        public: false,
      });
    } catch (error) {
      if (error.code === "auth/weak-password")
        setAlertMessage("Error: la contraseña debe contener números, mayúsculas y minúsculas, mínimo 8 caracteres");
      if (error.code === "auth/email-already-in-use")
        setAlertMessage("El correo ingresado ya se encuentra en uso.");
      if (error.code === "auth/invalid-email")
        setAlertMessage("Correo no válido.");
      else setAlertMessage("Error.");
      setAlert("error");
      setLoading(false);
    }
  }

  return (
    <>
      <Aside background="bg-purple-primary">
        <img src={AsideLogo} alt="logo" className="w-2/4  mb-10 mx-auto" />
      </Aside>
      <Logo />
      <Container image="bg-register-image" stretch="items-stretch">
        <Card padding="px-3 py-6" valign="justify-center">
          <form className="w-full" onSubmit={handleSubmit}>
            <img
              src={rmLogo}
              alt="El fan más real del Real Madrid"
              className="w-3/5  mb-6 mx-auto"
            />
            <InputRegister
              placeholder="Ingresa tu email"
              type="email"
              name="email"
              autofocus={true}
              margin="mb-3"
            />
            <InputRegister
              placeholder="Ingresa tu nombre"
              type="text"
              name="name"
              margin="mb-3"
            />
            <InputRegister
              placeholder="Ingresa tu teléfono"
              type="text"
              name="phone"
              margin="mb-3"
            />
            <InputRegister
              placeholder="Ingresa tu código"
              type="text"
              name="adiclub"
              margin="mb-3"
            />
            <InputRegister
              placeholder="Ingresa tu contraseña"
              type="password"
              name="password"
              margin="mb-1"
            />
            <p className="italic text-purple-primary text-xs flex items-center mb-1">
              (Numeros, mayúsculas y minúsculas, mínimo 8 caracteres)
            </p>
            <InputRegister
              placeholder="Confirma tu contraseña"
              type="password"
              name="confirmPassword"
              margin="mb-4"
            />
            <Checkbox
              name="age"
              value="first_checkbox"
              label="age"
              margin="mb-2"
            />

            <Checkbox
              name="age"
              value="first_checkbox"
              label="terms"
              link="/terminos-y-condiciones-y-aviso-de-privacidad"
              margin="mb-6"
            />

            <div className="flex">
              <Button variant="fill" type="submit" disabled={loading}>
                {loading ? "Verificando..." : "Continuar"}
              </Button>
            </div>

            {alert && <Alert type={alert} message={alertMessage} />}
          </form>
          <div className="flex w-full mt-8 items-center justify-around">
            <div className="text-center italic text-purple-primary">
              ¿Ya eres miembro?
            </div>
            <Button onClick={() => navigate("/login")}>Login</Button>
          </div>
        </Card>
      </Container>
    </>
  );
}

export default Register;
