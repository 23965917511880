function Button({ onClick, type, variant, children, disabled }) {
  const size = "px-8 py-2";

  const fill = `transition ease-in-out appearance-none bg-purple-primary hover:bg-purple-primary-500
  text-white py-3 px-12 focus:outline-none focus:shadow-outline mx-auto italic shadow-purple-primary-500/50 ${size}`;

  const outline = `transition ease-in-out appearance-none border-solid border border-purple-primary text-purple-primary italic hover:bg-purple-primary  hover:text-white ${size}`;

  return (
    <button
      onClick={onClick}
      type={type}
      className={variant === "fill" ? fill : outline}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default Button;
